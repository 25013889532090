.video-gallery {
  padding: 20px;
}

.video-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.video-link {
  text-decoration: none;
  color: inherit;
}

.custom-card {
  background-color: black;
  border: none;
  width: 100%;
}

.video-thumbnail {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: black;
}

.img-fluid {
  width: 150px;
  height: auto;
}

.card-body {
  text-align: center;
  background-color: black;
  color: white;
}

.card-title {
  font-size: 16px;
  margin-bottom: 10px;
}

.card-text {
  font-size: 16px;
}

.pagination-row {
  justify-content: center;
}

.video-link {
  text-decoration: underline;
  color: inherit;
}

.video-title {
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: bold;
  margin-bottom: 5px;
}