.home-page {
  padding: 20px;
}

.updates-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for photo cards */
/* Set the maximum width of the card to make it thinner */
.photo-update-card {
  max-width: 200px; /* Adjust as needed for the desired width */
  background-color: black;
  color: white;
  margin: auto; /* Center the card in the column */
  margin-bottom: 20px;
}

/* Set the thumbnail div to maintain aspect ratio */
.photo-update-card {
  background-color: black; /* Card background */
  border: none; /* No border for the card */
}

.photo-update-card .photo-thumbnail {
  width: 100%; /* Thumbnail width should fill the container */
  height: 200px; /* Set the thumbnail height */
  display: flex; /* Use flex to center the image */
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  overflow: hidden; /* Hide the overflow */
  background-color: black; /* Card background */
}

.photo-update-card .photo-thumbnail img {
  height: 200px; /* Set the image height */
  object-fit: contain; /* Ensure the whole image fits in the thumbnail */
  width: auto; /* Adjust the width automatically */
  max-width: 100%; /* Ensure the image does not exceed the container width */
  margin: auto; /* Center the image within the container */
  background-color: black; /* Card background */
}


.photo-update-card .card-body {
  background-color: black; /* Body background */
  color: white; /* Text color */
  padding: 0.5rem 1rem; /* Padding inside the card body */
  text-align: center; /* Center the text */
}

.photo-update-card .card-title,
.photo-update-card .card-text {
  font-size: 0.9rem; /* Font size */
  margin: 0; /* Remove default margins */
}

.mt-1 {
  margin-top: 0.05rem; /* Adjust this value as needed */
}








/* Styles for video cards */
.video-update-card {
  width: 100%;
  background-color: black;
  color: white;
  margin-bottom: 20px;
}

.video-update-card .card-img-top {
  height: 200px;
  width: auto;
  object-fit: cover;
}

.video-update-card .card-body {
  text-align: center;
}

.video-update-card .card-title,
.video-update-card .card-text {
  margin: 0;
  font-size: 0.9rem;
}

/* Styles for VOD cards */
.vod-update-card {
  width: 100%;
  background-color: black;
  color: white;
  margin-bottom: 20px;
}

.vod-update-card .card-img-top {
  height: 200px;
  width: auto;
  object-fit: cover;
}

.vod-update-card .card-body {
  text-align: center;
}

.vod-update-card .card-title,
.vod-update-card .card-text {
  margin: 0;
  font-size: 0.9rem;
}
