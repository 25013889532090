.model-detail-thumbnail-wrapper {
    width: 100%;
    height: 200px; /* Fixed height for photos */
    overflow: hidden;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .model-detail-thumbnail {
    width: auto;
    height: 100%;
    object-fit: cover; /* Cover the area for photos */
  }
  
  .video-vod-thumbnail-wrapper {
    width: 100%;
    /* No fixed height to maintain aspect ratio */
    overflow: hidden;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .video-vod-thumbnail {
    width: 100%; /* Fill the width */
    height: auto; /* Auto height to maintain aspect ratio */
  }
  