.photo-gallery {
  padding: 20px;
}

.search-card {
  background-color: black;
  color: white;
}

.search-card .card-img-top {
  height: 200px;
  object-fit: cover;
  background-color: black;
}

.search-card .card-body {
  text-align: center;
}

.search-card .card-title,
.search-card .card-text {
  font-size: 16px;
}

.search-card .card-img-top {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  background-color: black;
}
