.vod-gallery {
  padding: 20px;
}

.vod-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px; /* Space below each cell */
}

.vod-thumbnail {
  width: 100%; /* Ensure thumbnail width matches the column */
  overflow: hidden; /* Hide overflow */
}

.img-fluid {
  height: 240px; /* Fixed height for thumbnails */
  width: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the area without losing aspect ratio */
}

.custom-card {
  width: 100%; /* Match width of the thumbnail */
  background-color: black; /* Card background color */
  color: white; /* Text color */
}

.custom-card .card-body {
  text-align: center; /* Center text below the thumbnail */
  padding-top: 5px; /* Space between the thumbnail and text */
}

.custom-card .card-title, .custom-card .card-text {
  font-size: 0.9rem; /* Smaller font size for a compact look */
  margin: 0; /* Remove default margins */
}

.vod-thumbnail {
  width: 100%; /* Ensure thumbnail width matches the column */
  overflow: hidden; /* Hide overflow */
  display: flex; /* Add flex display */
  justify-content: center; /* Center content horizontally */
  background-color: black; /* Set background color to black */
}

.img-fluid {
  height: auto; /* Adjust height to maintain aspect ratio */
  width: auto; /* Adjust width to maintain aspect ratio */
  max-height: 240px; /* Set a maximum height for the thumbnail */
  object-fit: contain; /* Ensure the image is fully visible */
}

@media (min-width: 768px) {
  .vod-column {
    flex: 0 0 20%; /* Each column takes up 20% of the row */
    max-width: 20%; /* Each column's maximum width is 20% of the row */
  }
}

