.image-display-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
  }
  
  .image-display-container img {
    max-width: 100%; /* Ensure the image doesn't exceed the screen width */
    max-height: 100vh; /* Ensure the image doesn't exceed the screen height */
  }
  