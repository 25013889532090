.search-page {
    padding: 20px;
  }
  
  .search-form {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-form input {
    width: 300px;
    margin-right: 10px;
    padding: 5px;
  }
  
  .search-form button {
    padding: 5px 10px;
  }
  
  .search-column {
    margin-bottom: 20px;
  }
  
  .search-card {
    width: auto; /* Adjust the width to fit the content */
    margin: 0 auto; /* Center the card */
    border: 1px solid black;
    background-color: black;
    color: white;
  }
  
  .search-card img {
    height: 200px;
    width: auto; /* Adjust the width to maintain aspect ratio */
    object-fit: cover;
    display: block; /* Ensure the image is a block-level element */
    margin: 0 auto; /* Center the image */
    background-color: black; /* Set the background color to black */
  }

  @media (min-width: 992px) {
    .search-column {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  
  .search-card .card-body {
    text-align: center;
    background-color: black; /* Set the background color to black */
    color: white; /* Optional: Change the text color to white for better contrast */
  }
  
  .search-card .card-title {
    font-size: 16px;
    margin-bottom: 5px;
    background-color: black; /* Set the background color to black */
    color: white; /* Optional: Change the text color to white for better contrast */
  }

  .image-container {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .card-img-top {
    height: 200px;
    object-fit: cover;
  }
  
  .search-card .card-text {
    font-size: 14px;
    color: #666;
  }

  .search-video-card .card-img-top {
    background-color: black;
    height: 100px; /* Set a fixed height */
    width: auto; /* Allow the width to scale proportionally */
    object-fit: cover; /* Ensure the image covers the area without stretching */
    margin: 0 auto; /* Center the image horizontally */
  }
  
  .search-video-card {
    background-color: black;
  }
  
  .search-video-card .card-body {
    background-color: black;
    color: white;
  }
  
  .video-container {
    display: flex;
    justify-content: center;
    background-color: black;
  }
  
  .card-img-top {
    background-color: black;
  }

  .search-video-card .card-title {
    font-size: 16px; /* Adjust this value to match the photo card title size */
  }

  .search-video-card .card-body {
    text-align: center;
  }
  
  .search-video-card .card-text {
    margin: 0 auto; /* Center the text */
    color: #666;
  }

  .section-title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .search-vod-card {
    background-color: black;
    color: white;
  }
  
  .search-vod-card .card-img-top {
    height: 150px;
    object-fit: cover;
    background-color: black;
  }
  
  .search-vod-card .card-body {
    text-align: center;
    background-color: black;
  }

  .search-vod-card .card-title {
    font-size: 16px;
    color: white;
  }

  .search-vod-card .card-text {
    margin: 0 auto; /* Center the text */
    color: #666;
  }
  
  
  