/* Models.css */

.model-gallery {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .model-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .model-wrapper {
    width: 100%; /* set to the desired width */
    height: 200px; /* set a fixed height for all thumbnails */
    overflow: hidden; /* hide overflow */
  }
  
  .model-thumbnail {
    width: 100%; /* make the image fill the container */
    height: 100%;
    object-fit: cover; /* cover the container with the image without stretching */
    object-position: center center; /* center the image within the container */
  }
  
  .model-info {
    text-align: center;
  }
  
  .model-name {
    font-weight: bold;
    margin-top: 0.5rem;
  }
  
  /* Pagination row styling */
  .pagination-row {
    margin-top: 1rem;
  }
  
  .pagination-row .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .pagination-row .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  
  .pagination-row .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    opacity: 0.5;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .model-column {
      margin-bottom: 0.5rem;
    }
  
    .model-wrapper {
      padding-top: 100%; /* Adjust the padding-top for a different aspect ratio on smaller screens */
    }
  }
  