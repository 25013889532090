.links-page {
    background-color: #000000;
    color: #FFFF80;
  }
  
  .links-page a {
    color: #77FF00;
  }
  
  .links-page a:hover {
    color: #f01A8B;
  }
  
  .links-page img {
    margin-bottom: 20px;
  }
  
  .links-page h1, .links-page h2 {
    color: #FFFFFF;
  }
  